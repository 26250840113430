//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';
import ChangePasswordModal from './ChangePasswordModal.vue';

export default {
  components: { ChangePasswordModal },

  data() {
    return {
      changePasswordModal: {
        show: false,
      },
    };
  },

  computed: {
    ...mapState('login', ['currentAdmin']),

    status() {
      const { ADMIN_STATUS_LIST } = constant.admin;
      const { adminStatus } = this.currentAdmin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, adminStatus);
    },
  },
};
