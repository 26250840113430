//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import AccountReports from '~/views/Account/AccountReports/AccountReports.vue';
import AccountLog from '~/views/Account/AccountLog/AccountLog.vue';
import AccountInfo from '~/views/Account/AccountInfo/AccountInfo.vue';

export default {
  components: {
    AccountReports,
    AccountLog,
    AccountInfo,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadAccountReports(),
      this.loadAccountLog(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('accountReports', {
      loadAccountReports: 'loadData',
    }),
    ...mapActions('accountLog', {
      loadAccountLog: 'loadData',
    }),
  },
};
