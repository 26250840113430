//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      twoFaPin: '',
      currentPassword: '',
      newPassword: '',
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isDisabled() {
      const { twoFaPin, currentPassword, newPassword } = this;
      return !(twoFaPin && currentPassword && newPassword);
    },
  },

  watch: {
    value(value) {
      if (!value) {
        this.twoFaPin = '';
        this.currentPassword = '';
        this.newPassword = '';
      }
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setErrorNotification',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('accountInfo', ['changePassword']),

    change() {
      this.confirmAction({
        text: 'Are you sure you want to change password?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.changePassword({
              twoFaPin: this.twoFaPin,
              currentPassword: this.currentPassword,
              newPassword: this.newPassword,
            });
            this.setSuccessNotification('Password changed');
            this.$emit('input', false);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
