export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Action',
    },
    cell: {
      type: 'slot',
      name: 'action',
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
];
